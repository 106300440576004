import React from 'react';
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function App() {
  return (
    <div className="App">
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand style={{margin: 'auto'}}>
            Slacky AI beta 🌱
          </Navbar.Brand>
        </Container>
      </Navbar>
        <Container>
          <section className="section" style={{padding: '48px 0'}}>
            <img src="logo.svg" alt="Slacky logo" />
            {/* <img src="logo.png" alt="Slacky logo" height="64px" width="64px" /> */}
            <div style={{padding: '16px 0 0 0'}}>
              <h2>Your Slack AI Assistant</h2>
            </div>
            <h5>He's just a thin wrapper ✨</h5>
            <div style={{padding: '36px 0 0 0'}}>
              <img src="screenshot.png" alt="Slack App Screenshot" className="App-screenshot" />
            </div>
            <div className="Slack-button" style={{padding: '20px'}}>
              <a href="https://slack.com/oauth/v2/authorize?client_id=4818536755443.4818539024355&scope=app_mentions:read,channels:join,channels:read,chat:write,chat:write.public,commands,groups:read,im:read,im:write,mpim:history,mpim:write,team:read,users:read&user_scope=">
                <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
              </a>
            </div>
          </section>
          <section className='footer' style={{padding: '48px 0'}}>
            <div>Designed for Everyone 🪄</div>
            <div>Get answers quickly and easily 📚</div>
            <div>© Raya Labs</div>
          </section>
        </Container>
    </div>
  );
}

export default App;